import { getSPMURLByHostname } from './utils/globalHeaderUtils'

// Disable fetching JS for notifications and search, as we're not using them
window.GLOBAL_HEADER_DISABLED_SERVICES = true
window.SPM_URL = getSPMURLByHostname(window.location.hostname)

/**
This bit is necessary for module federations and What this does is create
an opportunity for webpack to coordinate with other remotes and decide
who will vend what, before beginning to execute the application. This approach
will increase RTT as webpack is unable to async load everything in one
roundtrip. This works together with the "bundle-loader" usage under webpack/base.js
**/

// Sharing modules requires that all remotes are initialized
// and can provide shared modules to the common scope
// As this is an async operation we need an async boundary (import())

// Using modules from remotes is also an async operation
// as chunks need to be loaded for the code of the remote module
// This also requires an async boundary (import())

// At this point shared modules initialized and remote modules are loaded import("./bootstrap");
import('./bootstrap')
